import { MultiSelect } from 'app/shared/select';
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { TimeSeriesSkeleton } from 'app/shared/skeleton';
import styles from './series.module.css';
import { DateRangePicker } from 'app/shared/datepickers';

const generateDefaultRange = () => {
  const [today] = new Date().toISOString().split('T');
  const [thirty_days_ago] = new Date(Date.parse(today) - 30 * 86400000).toISOString().split('T');
  return [thirty_days_ago, today];
};

const processRangeInDays = (min = 0, seconds_in_day = 86400000) => {
  const ranges = [];
  let x = min;
  for (let i = 0; i < 31; i++) {
    const [, mmm, dd] = new Date(x).toDateString().split(' ');
    ranges.push({ start: x, end: x + seconds_in_day - 1000, label: `${mmm} ${dd}` });
    x += seconds_in_day;
  }

  return ranges;
};

const processRangeInWeeks = (min = 0, seconds_in_day = 86400000) => {
  const ranges = [];
  let x = min;
  for (let i = 0; i < Math.ceil(100 / 7); i++) {
    const [, mmm, dd] = new Date(x).toDateString().split(' ');
    ranges[i] = {
      start: x,
      end: x + 6 * seconds_in_day - 1000,
      label: `${mmm} ${dd}`
    };
    x += 6 * seconds_in_day;
  }

  return ranges;
};

const processRangeInMonths = (min, max) => {
  // group in months
  const d = new Date(min);
  const year = d.getFullYear();
  const month = d.getUTCMonth();

  const ranges = [];
  let x = min;
  for (let i = 1; x <= max; i++) {
    const [, mmm, , yyyy] = new Date(x).toDateString().split(' ');
    ranges.push({
      start: x,
      end: Date.parse(new Date(year, month + i, 1)) - 1000,
      label: `${mmm} ${yyyy}`
    });

    x = Date.parse(new Date(year, month + i, 1));
  }

  return ranges;
};

export const processDateRange = (start, stop) => {
  const min = Date.parse(start);
  const max = Date.parse(stop);
  const difference = max - min;
  const seconds_in_day = 86400000;

  let ranges = [];
  if (difference / seconds_in_day <= 31) {
    ranges = processRangeInDays(min, seconds_in_day);
  } else if (difference / seconds_in_day <= 100) {
    ranges = processRangeInWeeks(min, seconds_in_day);
  } else {
    ranges = processRangeInMonths(min, max);
  }

  return ranges;
};

export const TimeSeriesChart = ({
  showDatePicker = true,
  showFilter = true,
  data = [],
  filter_data = [],
  graph_title = 'Graph title',
  key_filters = [],
  max_keys = 2,
  x_axis_key = 'day',
  is_loading_data = true,
  onDateRangeChange = () => {},
  onGroupChange = () => {},
  onKeyFilterChange = () => {}
}) => {
  const [chart_data, setChartData] = useState([]);
  const [selected_dates, setSelectedDates] = useState([]);
  const [selected_keys, setSelectedKeys] = useState([]);
  const [selected_filter, setSelectedFilter] = useState();

  useEffect(() => {
    const default_selection = generateDefaultRange();
    handleDateRangeChange(default_selection);

    if (!selected_keys.length && key_filters.length > 0) {
      setSelectedKeys(key_filters.filter((filter) => filter.default));
    }
  }, []);

  useEffect(() => {
    setChartData(() => data);
  }, [data]);

  const handleDateRangeChange = (value) => {
    const ranges = processDateRange(value[0], value[1]);
    onDateRangeChange(ranges);
    setSelectedDates(value);
  };

  const handleKeyFilterChange = (selections = []) => {
    if (
      key_filters.find((filter) => filter.is_fixed) &&
      !selections.find((selection) => selection.is_fixed)
    )
      return;
    if (selections.length > max_keys) return;
    setSelectedKeys(selections);
    if (onKeyFilterChange) onKeyFilterChange(selections);
  };

  const handleFilterChange = (selection) => {
    setSelectedFilter(selection);
    if (onGroupChange) onGroupChange(selection.value);
  };

  return (
    <div className={styles.wrapper}>
      <header>
        <div className={styles.title}>{graph_title}</div>
        <div className={styles.filters}>
          <MultiSelect
            options={key_filters}
            onChange={handleKeyFilterChange}
            value={selected_keys}
            isMulti
          />
          {!showDatePicker && showFilter && (
            <div>
              <MultiSelect
                options={filter_data}
                onChange={handleFilterChange}
                value={selected_filter}
              />
            </div>
          )}
          {showDatePicker && (
            <DateRangePicker
              onChange={handleDateRangeChange}
              initial_value={selected_dates}
              className={styles.rangePicker}
            />
          )}
        </div>
      </header>
      <section>
        {is_loading_data ? (
          <TimeSeriesSkeleton />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={chart_data}
              margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey={x_axis_key} />
              <Tooltip />
              <Legend spacing={25} shapeRendering="circle" />
              {selected_keys.map((selection) => (
                <Bar dataKey={selection.value} fill={selection.color} key={selection.value} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </section>
    </div>
  );
};
