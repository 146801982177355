import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPageTitle } from 'store/actions/header';
import { useTutorialService } from 'hooks/files/tutorial';
import { toast } from 'react-toastify';
import { TutorialItem } from './item';
import Spinner from 'app/shared/spinners/spinner-50/spinner-50';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { SimpleInput } from 'app/shared/input';
import styles from './list.module.css';
import { useDebouce } from 'hooks/debounce';
import { Spacer } from 'app/layouts/generic';
import { Button } from 'app/shared/button';
import { useUserService } from 'hooks/iam/user';
import useScreenSize from 'hooks/size';

export const TutorialListPage = () => {
  const { debounce } = useDebouce();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { id: user_id } = useSelector((state) => state.user_data);
  const { isLargeDesktop, isMiniDesktop, isTablet } = useScreenSize();

  const dispatch = useDispatch();
  const { fetchTutorials, searchTutorials } = useTutorialService();
  const { updateUserData } = useUserService();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search_keyword, setSearchKeyword] = useState('');

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Tutorials', path: '.' }]));

    //
    const data = { onboarding: { has_visited_tutorials_page: true } };
    updateUserData(user_id, { data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!search_keyword) {
      setPage(0);
      debounce(handleDataRequest(0), 500);
      return;
    }

    debounce(handleSearchRequest(search_keyword, 0), 500);
  }, [search_keyword]);

  const handleDataRequest = async (page) => {
    try {
      setLoading(true);
      const { tutorials, error } = await fetchTutorials({
        query_string: `sort_by=-priority,-created_on&page=${page}&population=50`
      });
      if (error) return toast.error(error);

      const sorted_tutorials = sortTutorials(tutorials);

      if (page === 0) {
        setItems(sorted_tutorials);
      } else {
        setItems((items) => [...items, ...sorted_tutorials]);
      }
      setPage((page) => page++);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keyword, page) => {
    const keys = 'title,description';
    try {
      setLoading(true);
      const { tutorials } = await searchTutorials(keys, keyword, {
        query_string: `sort_by=-priority,-created_on&page=${page}&population=50`
      });
      const sorted_tutorials = sortTutorials(tutorials);

      if (page === 0) {
        setItems(sorted_tutorials);
      } else {
        setItems((items) => [...items, ...sorted_tutorials]);
      }
      setPage((page) => page++);
    } finally {
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (search_keyword) {
      handleSearchRequest(search_keyword, page);
    } else {
      handleDataRequest(page);
    }
  };

  const sortTutorials = (tutorials = []) => {
    const welcome_tutorial = tutorials.find((tutorial) => tutorial.is_welcome);
    const other_tutorials = tutorials.filter((tutorial) => !tutorial.is_welcome);
    const sorted_tutorials = welcome_tutorial
      ? [welcome_tutorial, ...other_tutorials]
      : other_tutorials;

    return sorted_tutorials || [];
  };

  return (
    <div>
      <GridRow num_of_columns={is_mobile_view ? 1 : 5}>
        <GridColumn>
          <SimpleInput
            placeholder="Search tutorials"
            value={search_keyword}
            onInput={setSearchKeyword}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={2} />
      {items.length || !loading ? (
        items && !!items.length ? (
          <>
            <GridRow
              num_of_columns={
                is_mobile_view ? 1 : isTablet || isMiniDesktop ? 2 : isLargeDesktop ? 4 : 3
              }
            >
              {items.map((item) => (
                <GridColumn key={item.id}>
                  <TutorialItem data={item} />
                </GridColumn>
              ))}
            </GridRow>
            <Spacer multiple={8} />
            <GridRow num_of_columns={5}>
              <GridColumn span={2} />
              <Button type="secondary" text="load more" onClick={loadMore} loading={loading} />
              <GridColumn span={2} />
            </GridRow>
          </>
        ) : (
          <div className={styles.spinnerWrapper}>
            {' '}
            <span>No tutorials found at this time. Please try again later.</span>
          </div>
        )
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
