import cx from 'classnames';
import styles from './bubble.module.css';
import React, { useContext, useState } from 'react';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Spacer } from 'app/layouts/generic';
import { Icon } from 'assets/icons';
import { BubbleDisplayContext } from 'contexts/bubble';

export const BubbleDisplay = () => {
  const { items } = useContext(BubbleDisplayContext);

  const [is_hidden, setIsHidden] = useState(false);

  return (
    <>
      {!!Object.keys(items).length && (
        <div className={cx(styles.wrapper, styles.isClosed)}>
          <GridRow num_of_columns={1}>
            <GridColumn>
              <div className={styles.closeIcon}>
                {is_hidden && <span className={styles.badge}></span>}
                <Icon
                  name={is_hidden ? 'expand_up' : 'expand_down'}
                  onClick={() => setIsHidden((hid) => !hid)}
                />
              </div>
            </GridColumn>
          </GridRow>
          <Spacer multiple={1} />
          <div className={cx(styles.items, is_hidden && styles.hidden)}>{Object.values(items)}</div>
        </div>
      )}
    </>
  );
};
