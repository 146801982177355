import { Button } from 'app/shared/button';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from './column';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { useContactFileService } from 'hooks/file-config';
import { setPageTitle } from 'store/actions/header';
import useScreenSize from 'hooks/size';
import { PermissionsContext } from 'contexts/permissions';
import { toast } from 'react-toastify';

const CreateContactMapper = () => {
  const dispatch = useDispatch();
  const { id: tenant_id } = useSelector((state) => state.user_data);
  const { createMapping, fetchMapping, updateMapping } = useContactFileService();
  const { isDesktop, isMiniDesktop } = useScreenSize();
  const { permissions } = useContext(PermissionsContext);

  const [mappings_id, setMappingsId] = useState('');
  const [mapping_config, setMappingConfig] = useState({});
  const [mapping_components, setMappingComponents] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '/settings' },
        { title: 'File Configuration', path: '/settings/files' }
      ])
    );
  }, []);

  useEffect(() => {
    fetchMapping().then(({ mapping, error }) => {
      if (error) {
        toast.error(error);
        return;
      }
      if (mapping) {
        const { mappings, id } = mapping;
        setMappingsId(id);
        const mapping_components = mappings.map((mapping, index) =>
          buildContactColComponent(index, mapping)
        );
        setMappingComponents(mapping_components);
        return;
      }

      setMappingComponents(() => [buildContactColComponent(0)]);
    });
  }, []);

  const addContactColComponent = () => {
    const list = [...mapping_components, buildContactColComponent(mapping_components.length)];
    setMappingComponents(list);
  };

  const buildContactColComponent = (id, data) => {
    return (
      <Column
        key={id}
        id={id}
        data={data}
        onChange={handleContactColChange}
        onRemove={handleRemoveContactCol}
      />
    );
  };

  const handleContactColChange = (data) => {
    const { key, field_name: user_defined_value, internal_field } = data;
    setMappingConfig((col) => ({
      ...col,
      [key]: {
        property: user_defined_value,
        field: internal_field
      }
    }));
  };

  const handleRemoveContactCol = (id) => {
    setMappingComponents((mapping_components) =>
      mapping_components.filter((mapping) => mapping.props.id !== id)
    );
    setMappingConfig((col) => {
      delete col[id];
      return { ...col };
    });
  };

  const submit = () => {
    const mapped_fields = processFieldMapper(mapping_config);

    const data = {
      mappings: mapped_fields,
      tenant_id
    };

    setLoading(true);

    if (mappings_id) {
      return updateMapping(mappings_id, { data })
        .catch((e) => e)
        .finally(() => setLoading(false));
    }
    createMapping({ data })
      .then((response) => {
        if (!response) {
          return;
        }
        setMappingsId(response.id);
      })
      .catch((e) => e)
      .finally(() => setLoading(false));
  };

  const processFieldMapper = (fields) => {
    const result = Object.values(fields)
      .filter((value) => !!value.property)
      .reduce((prev, curr) => [...prev, { [curr.field.value]: curr.property }], []);
    return result;
  };

  return (
    <>
      <GridRow num_of_columns={isDesktop ? 4 : isMiniDesktop ? 3 : 2}>
        {isDesktop && <GridColumn />}
        <GridColumn span={2}>
          <BasicInfoSection title="Contact import files">
            {mapping_components}
            <GridRow num_of_columns={2}>
              <GridColumn />
              <GridColumn>
                <GridRow num_of_columns={6} preserveDefaultStyle={true}>
                  <GridColumn span={1}>
                    <Button onClick={addContactColComponent} icon_name="add" type="secondary" />
                  </GridColumn>
                  <GridColumn span={5}>
                    <Button
                      text="Save"
                      disabled={
                        mapping_components.length === 0 || !permissions['file_config:update']
                      }
                      onClick={submit}
                      loading={loading}
                    />
                  </GridColumn>
                </GridRow>
              </GridColumn>
            </GridRow>
          </BasicInfoSection>
        </GridColumn>
        {isDesktop && <GridColumn />}
      </GridRow>
    </>
  );
};

export default CreateContactMapper;
