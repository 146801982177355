import React, { useEffect } from 'react';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { APIConfiguration } from './api/api';
import { setPageTitle } from 'store/actions/header';
import { useDispatch } from 'react-redux';
import useScreenSize from 'hooks/size';

export const DeveloperSetting = () => {
  const dispatch = useDispatch();
  const { isDesktop, isMiniDesktop } = useScreenSize();

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '/settings' },
        { title: 'Developer', path: '/settings/developer' }
      ])
    );
  }, []);

  return (
    <>
      <GridRow num_of_columns={isDesktop ? 4 : isMiniDesktop ? 3 : 2}>
        {isDesktop && <GridColumn />}
        <GridColumn span={2}>
          <APIConfiguration />
        </GridColumn>
      </GridRow>
    </>
  );
};
