import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as AudienceService from 'services/audience';

export const useAudienceService = () => {
  const { token } = useSelector((store) => store.user_data);

  const addContacts = async (audience_ids = [], contact_ids = []) => {
    if (!audience_ids.length || !contact_ids.length) return;
    try {
      await Promise.all([
        audience_ids.map((audience_id) =>
          AudienceService.addContact(audience_id, { data: { contacts: contact_ids }, token })
        )
      ]);
      return true;
    } catch (e) {
      toast.error('Unable to add contact to selected audiences.');
      return false;
    }
  };

  const deleteAudienceById = async (id, options = {}) => {
    const { error, payload } = await AudienceService.deleteById(id, { ...options, token });
    if (error) {
      toast.error('Unable to delete audience at this time.');
    }

    toast.success('Audience deleted successfully.');
    return payload;
  };

  const deleteAudiences = async (options = {}) => {
    const { error, payload } = await AudienceService.deleteBulk({ ...options, token });
    if (error) {
      toast.error('Unable to delete audiences at this time.');
    }

    toast.success('Audiences deleted successfully.');
    return payload;
  };

  const fetchAudienceById = async (id, options = {}) => {
    const { error, payload } = await AudienceService.readById(id, { ...options, token });
    if (error) {
      return { audience: {} };
    }

    return { audience: payload };
  };

  const fetchAudiences = async (options = {}) => {
    const { error, payload } = await AudienceService.read({ ...options, token });
    if (error) {
      return { audiences: [], size: 0, error: 'Unable to fetch audiences at this time.' };
    }

    const {
      data: audiences,
      meta: { size }
    } = payload;
    return { audiences, size, error: null };
  };

  const removeContacts = async (audience_id, contact_ids) => {
    const { error } = await AudienceService.deleteContact(audience_id, {
      data: { contacts: contact_ids },
      token
    });
    if (error) {
      toast.error('Unable to remove contact(s) at this time.');
      return null;
    }

    return contact_ids;
  };

  const searchAudiences = async (keys, keyword, options = {}) => {
    const { error, payload } = await AudienceService.search(keys, keyword, { ...options, token });
    if (error) {
      return { audiences: [], size: 0 };
    }

    const {
      data: audiences,
      meta: { size }
    } = payload;
    return { audiences, size };
  };

  const updateAudience = async (id, options = {}) => {
    const { error, payload, status_code } = await AudienceService.updateById(id, {
      ...options,
      token
    });
    if (error) {
      toast.error(status_code < 500 ? error : 'Unable to update audience at this time.');
      return null;
    }

    return payload;
  };

  const updateAudiences = async (options = {}) => {
    const { error, payload, status_code } = await AudienceService.update({
      ...options,
      token
    });
    if (error) {
      toast.error(status_code < 500 ? error : 'Unable to update audiences at this time.');
      return null;
    }

    return payload;
  };

  return {
    addContacts,
    deleteAudienceById,
    deleteAudiences,
    fetchAudienceById,
    fetchAudiences,
    removeContacts,
    searchAudiences,
    updateAudience,
    updateAudiences
  };
};
