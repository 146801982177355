import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { Icon } from 'assets/icons';
import styles from './form.module.css';
import React, { useEffect, useState } from 'react';
import useValidator from 'hooks/validator';
import { neuronValidator } from 'validators/neuron';
import { useNeuronService } from 'hooks/automation/neuron';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addOneNeuronToStore } from 'store/actions/neuron';
import { NeuronTrigger } from './components/trigger';
import GmModal from 'app/shared/modal/modal';
import { NeuronResponseConfig } from './components/response';
import { NeuronResponseWidget } from './components/response/widget';
import { Spacer } from 'app/layouts/generic';
import useScreenSize from 'hooks/size';

export const NeuronForm = ({ data = {}, closeModal = () => {} }) => {
  const dispatch = useDispatch();
  const { isLargeDesktop } = useScreenSize();
  const { errors, form_is_valid, validateField } = useValidator(neuronValidator);
  const { createNeuron, updateNeuron } = useNeuronService();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [trigger_config, setTriggerConfig] = useState({});
  const [responses, setResponses] = useState({});
  const [response_to_edit, setResponseToEdit] = useState({});
  const [show_response_modal, setShowResponseModal] = useState(false);

  useEffect(() => {
    if (!Object.keys(data).length) return;
    setTitle(() => data.title);
    setTriggerConfig(() => ({
      event: data.event,
      source: data.source
    }));
    setResponses(() =>
      (data.responses || []).reduce(
        (sac, response) => ({ ...sac, [response.effect]: response }),
        {}
      )
    );
  }, [data]);

  useEffect(() => {
    validateField('title', title);
  }, [title]);

  const clearForm = () => {
    setTitle('');
  };

  const handleAdd = (response) => {
    setResponses((curr_responses) => ({ ...curr_responses, [response.effect]: response }));
  };

  const handleRemoveResponse = (data) => {
    const curr_responses = responses;
    delete curr_responses[data.effect];
    setResponses(() => ({ ...curr_responses }));
  };

  const handleEditResponse = (data) => {
    setResponseToEdit(data);
    setShowResponseModal(true);
  };

  const handleTriggerChange = (config = {}) => {
    validateField('event', config.event);
    validateField('source', config.source);
    setTriggerConfig(config);
  };

  const submit = async () => {
    const new_data = {
      title,
      code: title.toLowerCase(),
      ...trigger_config,
      responses: Object.values(responses)
    };

    setLoading(() => true);
    const { neuron } = data.id
      ? await updateNeuron(data.id, { data: new_data })
      : await createNeuron({ data: new_data });
    if (!neuron) toast.error(`Unable to ${data.id ? 'update' : 'create'} neuron.`);
    else {
      toast.success(`Neuron ${data.id ? 'updated' : 'created'} successfully.`);
      dispatch(addOneNeuronToStore({ ...data, ...new_data, ...neuron }));
      clearForm();
      closeModal();
    }
    setLoading(() => false);
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <GridRow num_of_columns={1}>
          <GridColumn>
            <DetailItem title="Title">
              <SimpleInput value={title} onInput={setTitle} error={errors.title} required />
            </DetailItem>
          </GridColumn>
        </GridRow>
        <NeuronTrigger data={trigger_config} errors={errors} onChange={handleTriggerChange} />
        <hr />
        <GridRow num_of_columns={1}>
          <div className={styles.responseSectionHeader}>
            <span>Responses</span>
            <span className={styles.icon}>
              <Icon
                name="add"
                onClick={() => {
                  setResponseToEdit({});
                  setShowResponseModal(true);
                }}
              />
            </span>
          </div>
        </GridRow>
        <Spacer multiple={4} />
        <div className={styles.responses}>
          {Object.values(responses).map((response) => (
            <NeuronResponseWidget
              key={response.effect}
              data={response}
              onDelete={handleRemoveResponse}
              onEdit={handleEditResponse}
            />
          ))}
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <GridRow num_of_columns={isLargeDesktop ? 2 : 1}>
          {isLargeDesktop && <GridColumn />}
          <GridColumn>
            <Button text="Save Changes" disabled={!form_is_valid || loading} onClick={submit} />
          </GridColumn>
        </GridRow>
      </div>
      <GmModal
        bodyClassName={styles.responseModal}
        title="Configure Response"
        show_title
        show_modal={show_response_modal}
        onClose={() => setShowResponseModal(false)}
      >
        <NeuronResponseConfig data={response_to_edit} onAdd={handleAdd} />
      </GmModal>
    </div>
  );
};
