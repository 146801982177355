import { GridColumn, GridRow } from 'app/layouts/grid';
import React from 'react';
import { TenantProfileSetting } from './tenant/index';
import { UserProfileSetting } from './user';
import { Spacer } from 'app/layouts/generic';
import { ReferralList } from './referral';
import useScreenSize from 'hooks/size';

export const ProfileSettings = () => {
  const { isMiniDesktop } = useScreenSize();
  return (
    <GridRow
      num_of_columns={isMiniDesktop && 3}
      tabletStyles={{ gridTemplateColumns: 'repeat(1,1fr)', gap: 0 }}
      mobileStyles={{ gap: 0 }}
    >
      <GridColumn>
        <UserProfileSetting />
      </GridColumn>
      <GridColumn span={2}>
        <TenantProfileSetting />
        <Spacer multiple={3} />
        <ReferralList />
      </GridColumn>
    </GridRow>
  );
};
